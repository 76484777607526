<template>
	<b-card>
		<!-- form -->
		<validation-observer ref="siteGeneralForm">
			<b-form class="mt-2" @submit.prevent="siteGeneralValidationForm">
				<b-row>
					<b-col sm="6">
						<b-form-group label="Suppressed List WVI" label-for="supressWVI">
							<b-form-checkbox v-model="isActiveSupressWVI" id="supressWVI" name="supressWVI" @change="activeSupressWVIChangeEvent($event)">
								Suppressed List WVI
							</b-form-checkbox>
							<small class="font-weight-bolder"><span class="text-danger">NOTE: </span>When the checkbox is checked Emails will be pushed to WVI. When the checkbox is unchecked Emails will not be pushed to WVI.</small>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="isActiveSupressWVI">
						<b-form-group label="Select WVI Client Account" label-for="selectedClient">
							<validation-provider #default="{ errors }" name="selectedClient" rules="required" :custom-messages="{ required: 'Please select WVI account'}">
								<Select2 id="selectedClient" v-model="selectedClient" :state="errors.length > 0 ? false:null" :options="wviClientsArray" name="selectedClient" @change="selectChangeEvent($event)" placeholder="Select WVI client" />
								<small class="text-danger">{{ errors[0] }}</small><br>
								<small class="font-weight-bolder"><span class="text-danger">NOTE: </span>Please select WVI account for this client.</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
							Save changes
						</b-button>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</b-card>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import {
		BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormRadioGroup, BFormRadio, BFormCheckbox
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { ref } from '@vue/composition-api'
	import { required } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BButton, BForm, BImg, BFormFile, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BFormRadioGroup, BFormRadio, ValidationProvider, ValidationObserver, BFormCheckbox
		},
		directives: {
			Ripple,
		},
		props: {
			generalData: {
				type: Object,
				default: () => { },
			},
		},
		created() {
			this.fetchSite();
			this.fetchWVIClientList();
		},
		data() {
			return {
				isActiveSupressWVI: false,
				selectedClient: '',
				wviClientsArray: [],
				required,
			}
		},
		methods: {
			activeSupressWVIChangeEvent(value) {
				if (!value) {
					this.selectedClient = '';
				}
			},
			selectChangeEvent(val) {
				this.selectedClient = val;
			},
			fetchSite() {
				this.$siteAPI.get('get', {
					'site_id': this.generalData.siteId,
					'wvi_section': true
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.isActiveSupressWVI = (response.site.supress_wvi_status_flag == 1 ? true : false);
						this.selectedClient = response.site.wvi_client_account_id;
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			},
			fetchWVIClientList() {
				this.$globalAPI.get('wvi/client/list', {
					'user_id': this.$root.authUserObject.id
				}).then((response) => {
					response = response.data;
					if (response.status) {
						for (const client of response.clients) {
							this.wviClientsArray.push({
								'id': client.id,
								'text': client.name
							});
						}
					}
				});
			},
			siteGeneralValidationForm() {
				this.$refs.siteGeneralForm.validate().then(success => {
					if (success) {
						this.$siteAPI.update('wvi', {
							'user_id': this.$root.authUserObject.id ?? '',
							'siteId': this.generalData.siteId,
							'supress_wvi_status_flag': (this.isActiveSupressWVI ? 1 : 0),
							'wvi_client_account_id': this.selectedClient,
						}).then((response) => {
							response = response.data;
							if (response.status) {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
		},
	}
</script>