<template>
	<b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">

		<!-- general tab -->
		<b-tab active>

			<!-- title -->
			<template #title>
				<feather-icon icon="UserIcon" size="18" class="mr-50" />
				<span class="font-weight-bold">Site General Settings</span>
			</template>

			<site-settings-general :general-data="siteDetails"/>
		</b-tab>
		<!--/ general tab -->

		<!-- general tab -->
		<b-tab>

			<!-- title -->
			<template #title>
				<feather-icon icon="MailIcon" size="18" class="mr-50" />
				<span class="font-weight-bold">Suppressed list WVI(Push emails to WVI)</span>
			</template>

			<site-settings-wvi :general-data="siteDetails"/>
		</b-tab>
		<!--/ general tab -->

		<!-- change password tab -->
		<b-tab>

			<!-- title -->
			<template #title>
				<feather-icon icon="LockIcon" size="18" class="mr-50" />
				<span class="font-weight-bold">Install Capture Script</span>
			</template>

			<site-settings-install :general-data="siteDetails" />
		</b-tab>
		<!--/ change password tab -->

		<!-- change password tab -->
		<b-tab>

			<!-- title -->
			<template #title>
				<feather-icon icon="ApertureIcon" size="18" class="mr-50" />
				<span class="font-weight-bold">Google Event Tracking Tag ID</span>
			</template>

			<google-event-tracking :general-data="siteDetails" />
		</b-tab>
		<!--/ change password tab -->

	</b-tabs>
</template>

<script>
	import { BTabs, BTab } from 'bootstrap-vue'
	import SiteSettingsGeneral from './siteSettingsGeneral.vue'
	import SiteSettingsInstall from './siteSettingsInstall.vue'
	import siteSettingsWvi from './siteSettingsWVI.vue'
	import googleEventTracking from './googleEventTracking.vue'

	export default {
		components: {
			BTabs,
			BTab,
			SiteSettingsGeneral,
			SiteSettingsInstall,
			siteSettingsWvi,
			googleEventTracking
		},
		data() {
			return {
				timeZoneList: {}
			}
		},
		computed: {
			siteDetails() {
				return this.$root.appSiteObject;
			}
		},
		methods: {
			
		}
	}
</script>