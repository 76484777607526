<template>
	<b-card>
		<b-row>
			<b-col sm="6">
				<b-form-group label="Google Event Tracking Tag ID" label-for="google_event_tracking_tag_id">
					<b-form-input v-model="googleEventTrackingTagID" name="google_event_tracking_tag_id" placeholder="Enter Tracking ID" />
				</b-form-group>
			</b-col>
			<b-col cols="12">
				<b-button type="button" @click="siteGeneralSubmit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
					Save changes
				</b-button>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import {
		BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormRadioGroup, BFormRadio, BFormCheckbox
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { ref } from '@vue/composition-api'
	import { required } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BButton, BForm, BImg, BFormFile, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BFormRadioGroup, BFormRadio, ValidationProvider, ValidationObserver, BFormCheckbox
		},
		directives: {
			Ripple,
		},
		props: {
			generalData: {
				type: Object,
				default: () => { },
			},
		},
		created() {
			this.fetchSite();
		},
		data() {
			return {
				googleEventTrackingTagID: '',
				required,
			}
		},
		methods: {
			fetchSite() {
				this.$siteAPI.get('get', {
					'site_id': this.generalData.siteId,
					'google_event_tracking_section': true
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.googleEventTrackingTagID = (response.site.google_event_tracking_tag_id ?? '');
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			},
			siteGeneralSubmit() {
				this.$siteAPI.update('google-event-tracking', {
					'user_id': this.$root.authUserObject.id ?? '',
					'siteId': this.generalData.siteId,
					'google_event_tracking_tag_id': (this.googleEventTrackingTagID ?? ''),
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'success',
								text: response.message,
							},
						});
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			},
		},
	}
</script>
