<template>
	<b-card>
		<!-- form -->
		<validation-observer ref="siteGeneralForm">
			<b-form class="mt-2" @submit.prevent="siteGeneralValidationForm">
				<b-overlay :show="isLoading" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
					<b-row>
						<b-col sm="6">
							<b-form-group label="siteURL" label-for="siteURL">
								<validation-provider #default="{ errors }" name="siteURL" rules="required" :custom-messages="{ required: 'Please enter site URL'}">
									<b-form-input v-model="siteObject.siteURL" name="siteURL" placeholder="Enter URL" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="6">
							<b-form-group label="Status" label-for="Status">
								<b-form-radio-group v-model="siteObject.status" :options="sites_active_options" class="mt-75" name="sites_active_switch" />
							</b-form-group>
						</b-col>
						<b-col sm="6">
							<b-form-group label="TimeZone" label-for="TimeZone">
								<validation-provider #default="{ errors }" name="timezone" rules="required" :custom-messages="{ required: 'Please enter Timezone'}">
									<Select2 v-model="siteObject.timeZone" :options="timezoneArray" name="timezone" @change="selectDateTypeChangeEvent($event)" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col sm="6">
							<b-form-group label="Zapier API key(For this site only)" label-for="Zapier API key(For this site only)">
								<b-form-input v-model="siteObject.zapierApiKey" :readonly="true" />
							</b-form-group>
						</b-col>
						<b-col cols="12">
							<b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
								Save changes
							</b-button>
							<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mt-2" @click="deleteSite()">
								Delete site
							</b-button>
						</b-col>
					</b-row>
				</b-overlay>
			</b-form>
		</validation-observer>
	</b-card>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import {
		BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormRadioGroup, BFormRadio, BOverlay
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { ref } from '@vue/composition-api'
	import { required } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BButton, BForm, BImg, BFormFile, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BFormRadioGroup, BFormRadio, ValidationProvider, ValidationObserver, BOverlay
		},
		directives: {
			Ripple,
		},
		props: {
			generalData: {
				type: Object,
				default: () => { },
			},
		},
		created() {
			this.fetchSite();
			this.fetchTimeZoneList();
		},
		data() {
			return {
				siteObject: {
					siteURL: null,
					status: 1,
					timeZone: null,
					zapierApiKey: null,
				},
				timezoneArray: [],
				sites_active_options: [
					{ text: 'Active site', value: 1 },
					{ text: 'Deactive site', value: 0 },
				],
				required,
				isLoading: false,
			}
		},
		methods: {
			selectDateTypeChangeEvent(val) {
				this.siteObject.timeZone = val;
			},
			fetchSite() {
				this.$siteAPI.get('get', {
					'site_id': this.generalData.siteId
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.siteObject.siteURL = response.site.siteURL;
						this.siteObject.status = response.site.status;
						this.siteObject.timeZone = response.site.timeZone;
						this.siteObject.zapierApiKey = response.site.zapierApiKey;
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			},
			fetchTimeZoneList() {
				this.$globalAPI.get('timezone/list').then((response) => {
					response = response.data;
					if (response.status) {
						for (const [val, key] of Object.entries(response.timezones)) {
							this.timezoneArray.push({
								'id': key,
								'text': key + ' - ' + val
							});
						}
					}
				});
			},
			siteGeneralValidationForm() {
				this.$refs.siteGeneralForm.validate().then(success => {
					if (success) {
						this.isLoading = true;
						this.$siteAPI.update('general', {
							'user_id': this.$root.authUserObject.id ?? '',
							'siteId': this.generalData.siteId,
							'siteURL': this.siteObject.siteURL,
							'status': this.siteObject.status,
							'timeZone': this.siteObject.timeZone,
						}).then((response) => {
							this.isLoading = false;
							response = response.data;
							if (response.status) {
								this.$root.appSiteObject = response.site; /* UPDATE ROOT AUTH SITE */
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
			/**
			 * FOR DELETE SITE AND WHOLE DATA OF THAT SITE
			 */
			deleteSite() {
				this.$swal({
					title: 'Are you sure ?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, delete it!',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1',
					},
					buttonsStyling: false,

				}).then((result) => {
					if (result.value) {
						this.isLoading = true;
						this.$siteAPI.delete(this.generalData.siteId).then((response) => {
							response = response.data;

							if (response.status) {
								EventBus.$emit('onDeleteSiteChangeSiteEvent', true); /* FOR PREVIEW CAPTURE */
								this.changeAppSite(response.site);
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$router.push("/add-new-site").catch(() => { }); /* Refresh current page for add new site */
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						})
					} else {
					}
				})
			},
			/* CHANGE SESSION AND CURRENT SITE */
			changeAppSite(site) {
				this.$root.appSiteObject = site;
				this.selectedSiteObject = site;
				this.$siteAPI.setSession({
					'site_id': site.siteId,
				}).then((response) => {
					response = response.data;
					this.$store.commit('app/TOGGLE_OVERLAY', false); /* FOR BLUR BACKEND */
					this.searchQuery = ''; /* EMPTY SEARCH BOX */
					this.$router.push("/summary").catch(() => { }); /* Refresh current page for set site to globle */
					EventBus.$emit('eventChangeAppSite', true); /* Fetch current site data */
				});
			}
		},
	}
</script>