<template>
	<b-card title="Install Capture Script" sub-title="Installation Methods">
		<b-card-text>
			Once the script is installed, your dashboard will begin populating data all about your bars and visitors. This only needs to be done once per site.
		</b-card-text>
		<div class="card shadow-none border-primary mt-2">
			<div class="card-body">
				<b-alert show variant="primary">
					<h4 class="alert-heading">
						I can install code myself
					</h4>
					<div class="alert-body">
						<span>If you know HTML and can edit your site, our install only takes one line of code.</span>
					</div>
				</b-alert>
				<p class="card-text"> To install Capture, copy and paste the following code right before your closing tag.</p>
				<pre class="font-weight-bolder">&lt;script src="{{captureJsName}}.js" type="text/javascript" charset="utf-8" async="async"&gt;&lt;/script&gt;</pre>
				<p class="card-text">That's it! You'll never need to update the code on your site again.</p>
			</div>
		</div>
		<div class="card shadow-none border-primary mt-2">
			<div class="card-body">
				<b-alert show variant="primary">
					<h4 class="alert-heading">
						I use WordPress
					</h4>
					<div class="alert-body">
						<span>If you use WordPress you'll love our zero-configuration custom plugin. Just download,
							install and you're done.</span>
					</div>
				</b-alert>
				<dl>
					<dt>1) Download Your Plugin</dt>
					<dd>
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :href="captureJsPlugin">
							Download
						</b-button>
						<small> 1MB</small>
					</dd>
					<dt>2) Install Your Plugin</dt>
					<dd>Now you just need to install the .zip file you downloaded it by uploading it to your
						WordPress site. The plugin was preconfigured specifically for your site
						({{captureSiteUrl}}). After you install and activate there are no settings to
						adjust!</dd>
				</dl>
				<p><strong>Need More Help?</strong>
					Try reading these&nbsp;
					<b-link href="https://wordpress.org/support/article/managing-plugins/" target="_blank">helpful instructions for installing plugins on WordPress</b-link>
				</p>
			</div>
		</div>
	</b-card>
</template>

<script>
	import { BButton, BCard, BAlert, BLink, BCardText } from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'

	export default {
		components: {
			BButton, BCard, BAlert, BLink, BCardText
		},
		directives: {
			Ripple,
		},
		props: {
			generalData: {
				type: Object,
				default: () => { },
			},
		},
		data() {
			return {
				captureJsName: (window.location.origin + '/data/' + this.generalData.siteAutoId),
				captureJsPlugin: ('api/site/wordpress_plugin/'),
				captureSiteUrl: this.generalData.siteURL,
			}
		},
		computed: {},
		methods: {},
	}
</script>
<style lang="scss" scoped>
	pre {
		padding: 0.5rem;
		margin-bottom: 1rem;
		border-radius: .5rem;
	}

	dd {
		padding-left: 1rem;
	}
</style>